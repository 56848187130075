import couponCodeService from '@src/utils/couponCodeService';
import { PRODUCT_COOKIES } from '@src/constants';
import useIs4ppOrEo from './useIs4ppOrEo';

type offer = '4pp' | 'essential' | 'coupon';

export const useDiscount = () => {
  const { isE0 } = useIs4ppOrEo();
  const { getOffer } = couponCodeService();
  const currentOffer = getOffer(isE0 ? 'essential' : '4pp');

  if (!currentOffer) return;
  if (currentOffer === PRODUCT_COOKIES.PERSONALISED_PLUS.value.FIFTY_PERCENT_OFF) {
    return { text: '50% off the first month', color: '#23A4A5' };
  }
  if (currentOffer === PRODUCT_COOKIES.PERSONALISED_PLUS.value.FIFTY_FIVE_PERCENT_OFF) {
    return { text: '55% off the first month', color: '#23A4A5' };
  }
  if (currentOffer === PRODUCT_COOKIES.PERSONALISED_PLUS.value.THIRTY_OFF_TREE_MONTH) {
    return { text: '30% off the first 3 orders', color: '#23A4A5' };
  }
  if (currentOffer === PRODUCT_COOKIES.PERSONALISED_PLUS.value.SEVENTY_OFF) {
    return { text: '70% off the first month', color: '#23A4A5' };
  }
  if (currentOffer === PRODUCT_COOKIES.PERSONALISED_PLUS.value.TWENTY_FIVE_GBP_OFF) {
    return { text: '£25 off the first month', color: '#23A4A5' };
  }
  if (currentOffer === PRODUCT_COOKIES.PERSONALISED_PLUS.value.FREE_TRIAL) {
    return { text: '2 WEEK FREE TRIAL', color: '#51AFD5' };
  }
  if (currentOffer === PRODUCT_COOKIES.PERSONALISED_PLUS.value.FB) {
    return { text: '2 WEEK FREE TRIAL', color: '#51AFD5' };
  }
  if (currentOffer === PRODUCT_COOKIES.PERSONALISED_PLUS.value.GA) {
    return { text: '2 WEEK FREE TRIAL', color: '#51AFD5' };
  }
  if (currentOffer === PRODUCT_COOKIES.PERSONALISED_PLUS.value.TIKTOK) {
    return { text: '2 WEEK FREE TRIAL', color: '#51AFD5' };
  }
  if (currentOffer === PRODUCT_COOKIES.ESSENTIAL_ONE.value.FREE_TRIAL) {
    return { text: 'FREE TRIAL', color: '#51AFD5' };
  }
  if (currentOffer === PRODUCT_COOKIES.ESSENTIAL_ONE.value.REFERRAL) {
    return { text: 'FREE TRIAL', color: '#51AFD5' };
  }
  if (currentOffer === PRODUCT_COOKIES.ESSENTIAL_ONE.value.AFFILIATES) {
    return { text: 'FREE TRIAL', color: '#51AFD5' };
  }
};
