import { log } from 'console';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { IRecommendedProduct } from '@src/types/product';
import {
  IGetProductQueryResponse,
  IUserSubscriptionsQueryResponse,
} from '@src/types/query-response';
import { GET_PRODUCT } from '@src/queries/product';
import { ERROR_MESSAGES, PAGE_URL_TO_PRODUCT_CODE, PRODUCT_COOKIES } from '@src/constants';
import { getCookie } from '@src/services/cookies';
import { COOKIES } from '@src/constants/cookies';
import { checkFreeTrial } from '@src/services/global';
import couponCodeService from '@src/utils/couponCodeService';
import { ACCOUNT_RESULT_OFFER_CODES, ACCOUNT_RESULT_PACK_NAMES } from '../constants/constants';
import { SUBSCRIPTIONS } from '@src/queries/orders';
import { GlobalContext } from '@src/store/global-state';
import { formatGraphQLError } from '@src/services/format';
import { ISubscription } from '@src/types/subscription';
import { SUBSCRIPTION_SWITCH_LINE } from '@src/queries/subscription';
import useGlobalError from '@src/hooks/useGlobalError';
import { ResultPageContext } from '../store/store';
import {
  essentialPageVisitSetCookie,
  fourPPPageVisitSetCookie,
  getPersonalizedPageVisitSetCookie,
} from '@src/utils/coupons';
import { trackEvent } from '@src/services/tracking/tracking';

const useAccountResult = () => {
  const { setErrorModalMessage } = useContext(GlobalContext);
  const {
    personalisedProduct,
    setPersonalisedProduct,
    setNutrientCountTotal,
    displayProductCode,
    setDisplayProductCode,
    toggleUpgradeSection,
    setIsErrorModalVisible,
    partnership,
    setPartnership,
    freeTrial,
    setFreeTrial,
    showModalSwitchSubscription,
    setShowModalSwitchSubscription,
    setSubscription,
    subscription,
    setPersonalisedProductPills,
    dataGoals,
    dataRecommendedProducts,
    error: errorResultandGoals,
    loadingRecommended,
    loadingGoals,
  } = useContext(ResultPageContext);

  const params = new URLSearchParams(location.search);
  const { getOffer } = couponCodeService();

  // _____ API CALLS _____
  const [
    getProduct,
    { data: dataProduct, loading: loadingProduct, error: errorProduct },
  ] = useLazyQuery<IGetProductQueryResponse>(GET_PRODUCT, {
    fetchPolicy: 'network-only',
  });

  const [
    getSubscriptions,
    { data: dataSubscriptions, loading: loadingSubscriptions, error: errorSubscriptions },
  ] = useLazyQuery<IUserSubscriptionsQueryResponse>(SUBSCRIPTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setSubscription(dataSubscriptions);
      setShowModalSwitchSubscription(true);
    },
    onError: error => {
      error.message && setErrorModalMessage(error.message && formatGraphQLError(error.message));
    },
  });

  const [subscriptionSwitchLine, { loading: loadingSubscriptionSwitchLine }] = useMutation(
    SUBSCRIPTION_SWITCH_LINE,
    {
      onError: error => {
        setShowModalSwitchSubscription(false);
        error.message && setErrorModalMessage(error.message && formatGraphQLError(error.message));
      },
    }
  );

  // _____ FUNCTIONS _____
  const chooseProductToDisplay = () => {
    // 2 - user started consultation from product page
    const pageName = sessionStorage.getItem('consultationProduct');
    if (pageName) {
      return PAGE_URL_TO_PRODUCT_CODE[pageName].productCode;
    }

    if (dataRecommendedProducts) {
      const isPpPresent = dataRecommendedProducts.health_recommendedProducts?.find(
        (recommendation: any) => recommendation.subtype === 'personalised'
      );
      const isEoPresent = dataRecommendedProducts.health_recommendedProducts?.find(
        (recommendation: any) => recommendation.subtype === 'theone'
      );

      if (!isPpPresent && !isEoPresent) {
        setIsErrorModalVisible(true);
        return '';
      }

      if (params.get('showProduct')?.toLowerCase() === 'eo' || !isPpPresent) {
        toggleUpgradeSection(false);
        essentialPageVisitSetCookie();
        return ACCOUNT_RESULT_OFFER_CODES.EssentialOne;
      }

      if (isPpPresent && !isEoPresent) {
        toggleUpgradeSection(false);
      }

      if (params.get('showProduct')?.toLowerCase() === '4pp' || !isEoPresent) {
        toggleUpgradeSection(false);
        fourPPPageVisitSetCookie();
        return ACCOUNT_RESULT_OFFER_CODES.PersonalisedPlus;
      }
      if (getPersonalizedPageVisitSetCookie()) {
        const cookieValue = getPersonalizedPageVisitSetCookie();
        if (cookieValue === 'eo') {
          return ACCOUNT_RESULT_OFFER_CODES.EssentialOne;
        }
        if (cookieValue === '4pp') {
          return ACCOUNT_RESULT_OFFER_CODES.PersonalisedPlus;
        }
      }
    }

    const trialData = checkFreeTrial('essentialOne', getCookie(COOKIES.essentialOnePartnership));
    // 1 - user has free trial
    if (trialData.isActive) {
      return trialData.productCode;
    }
    // 3 - user chose a specific issue in the consultation
    // TODO: Update when LANA is fixed to support correct supplement issues (@Makan for any questions)
    // if (checkHasSupplementIssue()) {
    //   return ACCOUNT_RESULT_OFFER_CODES.EssentialOne
    // }
    // 4 - 4PP otherwise
    return ACCOUNT_RESULT_OFFER_CODES.PersonalisedPlus;
  };

  const getProductId = (
    recommendations: IRecommendedProduct[],
    displayProductCode: ACCOUNT_RESULT_OFFER_CODES | ''
  ) => {
    let product: IRecommendedProduct | undefined;

    if (displayProductCode === ACCOUNT_RESULT_OFFER_CODES.PersonalisedPlus) {
      product = recommendations.find(recommendation => recommendation.id.startsWith('sn'));
      setPartnership(getOffer('4pp'));

      if (partnership) {
        setFreeTrial(checkFreeTrial('personalisedPlus', partnership).isActive);
      }
    }

    // sometimes user can't get an actual PP product even when that's what they opted for
    if (displayProductCode === ACCOUNT_RESULT_OFFER_CODES.EssentialOne || !product) {
      if (!product) setDisplayProductCode(ACCOUNT_RESULT_OFFER_CODES.EssentialOne);
      product = recommendations.find(recommendation =>
        recommendation.id.startsWith('essential-one')
      );
      setPartnership(getOffer('essential'));

      if (partnership) {
        setFreeTrial(checkFreeTrial('essentialOne', partnership).isActive);
      }
    }
    return product?.id;
  };

  const loadProduct = (displayProductCode: ACCOUNT_RESULT_OFFER_CODES | '') => {
    const productId = getProductId(
      dataRecommendedProducts?.health_recommendedProducts,
      displayProductCode
    );

    productId &&
      getProduct({
        variables: {
          slug: productId,
          id: '',
          freeTrial: freeTrial,
          partnership: partnership,
        },
      });
  };

  const has4PPDiscount = () => {
    if (isSubscribedToRecommendedProduct) return false;

    const discountCookie = getOffer('4pp') || '';
    return [
      PRODUCT_COOKIES.PERSONALISED_PLUS.value.FIFTY_PERCENT_OFF,
      PRODUCT_COOKIES.PERSONALISED_PLUS.value.FIFTY_FIVE_PERCENT_OFF,
      PRODUCT_COOKIES.PERSONALISED_PLUS.value.THIRTY_OFF_TREE_MONTH,
      PRODUCT_COOKIES.PERSONALISED_PLUS.value.SEVENTY_OFF,
      PRODUCT_COOKIES.PERSONALISED_PLUS.value.TWENTY_FIVE_GBP_OFF,
    ].includes(discountCookie);
  };

  const shouldShowFreeTrialLabel = () => {
    if (has4PPDiscount()) {
      return false;
    }

    const isThereFreeTrial =
      (displayProductCode === ACCOUNT_RESULT_OFFER_CODES.EssentialOne && getOffer('essential')) ||
      (displayProductCode === ACCOUNT_RESULT_OFFER_CODES.PersonalisedPlus && getOffer('4pp'));

    return !!isThereFreeTrial;
  };
  const getAllSwitchLinePromises = (
    filteredSubscriptions: ISubscription[],
    currentRecommendedProductVariant: string
  ) =>
    filteredSubscriptions.map(s => {
      return new Promise(async resolve => {
        await subscriptionSwitchLine({
          variables: {
            subscriptionId: s.id,
            variant: currentRecommendedProductVariant,
          },
        });
        resolve(true);
      });
    });

  const confirmUpdateSubscription = async () => {
    const currentRecommendedProductSubtype =
      currentRecommendedProduct?.subtype === 'theone'
        ? 'essential-one'
        : currentRecommendedProduct?.subtype;

    const currentRecommendedProductVariant = currentRecommendedProduct?.variant?.replace(
      'essential-one-',
      ''
    );

    const filteredSubscriptions =
      currentRecommendedProductSubtype && subscription
        ? subscription?.user_subscriptions?.filter(s =>
            s.product?.sku?.includes(currentRecommendedProductSubtype)
          )
        : [];

    if (filteredSubscriptions.length > 0 && currentRecommendedProductVariant) {
      await Promise.all(
        getAllSwitchLinePromises(filteredSubscriptions, currentRecommendedProductVariant)
      );
      setShowModalSwitchSubscription(false);
      window.location.reload(); //! SEE if there is a better way
    }
  };

  // _____ EFFECTS _____
  const currentRecommendedProduct = useMemo(() => {
    if (!personalisedProduct) {
      return null;
    }
    return dataRecommendedProducts?.health_recommendedProducts?.find(
      (p: any) => p.id === personalisedProduct?.id
    );
  }, [personalisedProduct, dataRecommendedProducts]);

  useEffect(() => {
    if (!!dataGoals?.length && !!dataRecommendedProducts?.health_recommendedProducts) {
      const productCode = chooseProductToDisplay() as ACCOUNT_RESULT_OFFER_CODES;
      if (!displayProductCode || displayProductCode !== productCode) {
        setDisplayProductCode(productCode);
        loadProduct(productCode);
      }
    }
  }, [dataGoals, dataRecommendedProducts]);

  useEffect(() => {
    if (dataProduct?.product_getProduct) {
      const product = {
        ...dataProduct.product_getProduct,
        pills: dataProduct.product_getProduct.pills?.length
          ? [...dataProduct.product_getProduct.pills]
          : [],
      };

      // Get unique nutrient count
      const uniqueNutrientCount = new Set(product.pills.map(pill => pill.nutrients).flat()).size;

      setNutrientCountTotal(uniqueNutrientCount);
      setPersonalisedProduct(product);
      setPersonalisedProductPills(product.pills);
    }
  }, [displayProductCode, dataProduct]);

  const selectedGoals = useMemo(() => {
    const healthGoals = dataGoals;
    if (!healthGoals) {
      return [];
    }
    return healthGoals.filter((goal: any) => goal.selected);
  }, [dataGoals]);

  const currentPricePlan = useMemo(() => {
    if (personalisedProduct && personalisedProduct.plans && personalisedProduct.plans.length > 0) {
      return personalisedProduct.plans[0];
    }
  }, [personalisedProduct]);

  // _____ Data _____
  const loading =
    (!personalisedProduct && !dataProduct) ||
    loadingProduct ||
    loadingRecommended ||
    loadingGoals ||
    loadingSubscriptions ||
    loadingSubscriptionSwitchLine;

  const error = errorProduct || errorResultandGoals;
  useGlobalError(!!error, ERROR_MESSAGES.generic);

  const isSubscribedToRecommendedProduct = currentRecommendedProduct?.isSubscribed || false;
  const shouldUpdatePack = currentRecommendedProduct?.updateAvailable || false;
  const getPackName = displayProductCode && ACCOUNT_RESULT_PACK_NAMES[displayProductCode];

  // _____ RETURN _____
  return {
    loading,
    has4PPDiscount,
    shouldShowFreeTrialLabel,
    shouldUpdatePack,
    isSubscribedToRecommendedProduct,
    getSubscriptions,
    errorSubscriptions,
    currentPricePlan,
    getPackName,
    confirmUpdateSubscription,
    selectedGoals,
    currentRecommendedProduct,
  };
};

export default useAccountResult;
